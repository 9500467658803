<template>
  <div
    v-if="salesInvoice
    "
  >
    <router-link :to="$objectViewRoute(salesInvoice)">
      {{ `${salesInvoice.code}` }}
    </router-link>
  </div>
</template>

<script>
import formMixin from "@/mixins/form-mixin";

export default {
  name: "sales-invoice",

  components: {},

  mixins: [formMixin],

  props: {
    salesInvoice: {
      type: Object,
      default: () => ({}),
      description: "Sales invoice",
    },
  },

  data() {
    return {};
  },

  setup() {},

  created() {},

  methods: {},

  watch: {
    salesInvoice(salesInvoice) {},
  },
};
</script>
