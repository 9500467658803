<template>
  <div
    v-if="transaction
    "
  >
    <router-link :to="$objectViewRoute(transaction)">
      {{ `${transaction.code}` }}
    </router-link>
  </div>
</template>

<script>
import formMixin from "@/mixins/form-mixin";

export default {
  name: "transaction",

  components: {},

  mixins: [formMixin],

  props: {
    transaction: {
      type: Object,
      default: () => ({}),
      description: "Transaction",
    },
  },

  data() {
    return {};
  },

  setup() {},

  created() {},

  methods: {},

  watch: {
    transaction(transaction) {},
  },
};
</script>
