export default {
  type: "transactions",
  price: "",
  buyer_payment_method: "",
  buyer_shipping_method: "",
  buyer_shipping_Address: "",
  seller_payment_method: "",
  seller_shipping_method: "",
  seller_shipping_Address: "",
  relationshipNames: ["seller", "buyer", "sellerLocation", "buyerLocation", "vehicle"],
  seller: {
    type: "organizations",
    id: null,
  },
  buyer: {
    type: "organizations",
    id: null,
  },
  sellerLocation: {
    type: "locations",
    id: null,
  },
  buyerLocation: {
    type: "locations",
    id: null,
  },
  vehicle: {
    type: "vehicles",
    id: null,
  },
};
