<template>
  <div class="elite-tabs-wrapper-content">
    <div class="title-header">
      <div class="row">
        <div class="col-6">
          <collecte :collecte="collecte"/>
        </div>
        <div class="col-6 text-right">
          <base-button
            class="btn-outline-danger"
            icon
            size="sm"
            v-if="collecte.status == 'DRAFT' && $currentUserCan($permissions.PERM_DELETE_COLLECTES)"
            @click="deleteCollecte"
          >
            <span class="btn-inner--icon">
              <i class="far fa-trash-alt"></i>
            </span>
            <span class="btn-inner--text">
              {{ $t("COMMON.DELETE") }}
            </span>
          </base-button>

          <base-button
            class="elite-button add"
            icon
            size="sm"
            v-if="collecte.status == 'DRAFT' && $currentUserCan($permissions.PERM_EDIT_COLLECTES)"
            @click="editCollecte"
          >
            <span class="btn-inner--icon">
              <i class="far fa-edit"></i>
            </span>
            <span class="btn-inner--text">
              {{ $t("COMMON.EDIT") }}
            </span>
          </base-button>
          <base-dropdown
            title-classes="btn dropdown-button"
            v-if="collecte.status == 'DRAFT' || collecte.status == 'PENDING' || collecte.status == 'COLLECTED'"
            menu-on-right
            :has-toggle="false"
          >
            <template slot="title">
              <i class="fas fa-ellipsis-v"></i>
            </template>
            <button
              class="view"
              @click="validateCollecte(collecte)"
              v-if="collecte.status == 'DRAFT'"
            >
              <span>{{ $t("COLLECTES.VALIDATE_COLLECTE") }}</span>
            </button>
            <button
              class="view"
              @click="collectCollecte(collecte)"
              v-if="collecte.status == 'PENDING'"
            >
              <span>{{ $t("COLLECTES.COLLECT_COLLECTE") }}</span>
            </button>
            <button
              class="view"
              @click="deliverCollecte(collecte)"
            >
              <span>{{ $t("COLLECTES.DELIVER_COLLECTE") }}</span>
            </button>
            <button
              class="view"
              @click="cancelCollecte(collecte)"
              v-if="collecte.status == 'DRAFT' || collecte.status == 'PENDING' || collecte.status == 'COLLECTED'"
            >
              <span>{{ $t("COLLECTES.CANCEL_COLLECTE") }}</span>
            </button>
          </base-dropdown>
          
        </div>
      </div>
    </div>

    <div class="all-infos">
      <div class="all-infos-text">
        <dl class="row">
          <dt>{{ $t("COLLECTES.STATUS") }}</dt>
          <dd>
            {{ $t(`COLLECTES.STATUS_${collecte.status}`) }}
          </dd>
        </dl>

        <dl class="row" v-if="collecte.collected_at">
          <dt>{{ $t("COLLECTES.COLLECTED_AT") }}</dt>
          <dd>
            {{
              $formatDate(collecte.collected_at, "dddd D MMMM YYYY")
            }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COLLECTES.COLLECTED_LOCATION") }}</dt>
          <dd>
            <div v-html="collecte.collect_location"></div>
          </dd>
        </dl>

        <dl class="row" v-if="collecte.delivered_at">
          <dt>{{ $t("COLLECTES.DELIVERED_AT") }}</dt>
          <dd>
            {{
              $formatDate(collecte.delivered_at, "dddd D MMMM YYYY")
            }}
          </dd>
        </dl>
        
        <dl class="row">
          <dt>{{ $t("COLLECTES.DELIVERED_LOCATION") }}</dt>
          <dd>
            <div v-html="collecte.delivery_location"></div>
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.CREATED_AT") }}</dt>
          <dd v-if="collecte.created_at">
            {{ $formatDate(collecte.created_at) }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.UPDATED_AT") }}</dt>
          <dd v-if="collecte.updated_at">
            {{ $formatDate(collecte.updated_at) }}
          </dd>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
import swal from "sweetalert2";
import Collecte from "@/components/Collecte";
export default {
  name: "collecte-view-global",

  components: {
    Collecte
  },

  props: ["collecte"],

  data() {
    return {};
  },

  computed: {},

  created() {},

  methods: {
    validateCollecte() {
      this.$emit("onValidateCollecte", this.collecte);
    },
    collectCollecte() {
      this.$emit("onCollectCollecte", this.collecte);
    },
    deliverCollecte() {
      this.$emit("onDeliverCollecte", this.collecte);
    },
    cancelCollecte() {
      this.$emit("onCancelCollecte", this.collecte);
    },
    editCollecte() {
      this.$emit("onEditCollecte", this.collecte);
    },
    deleteCollecte() {
      this.$emit("onDeleteCollecte", this.collecte);
    },
  },

  mounted() {},

  watch: {
    collecte(collecte) {},
  },
};
</script>
