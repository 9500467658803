<template>
  <form
    class="add-form"
    ref="profile_form"
    @submit.prevent="handleSubmit"
    @keydown.enter.prevent="() => {}"
  >
    <div
      class="form-wrapper full"
      v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)"
    >
      <!-- Organization -->
      <base-input
        :label="`${$t('TRANSACTIONS.VEHICLE')} (*)`"
        :placeholder="$t('TRANSACTIONS.VEHICLE')"
      >
        <vehicle-selector
          :vehicle="transaction.vehicle.id"
          :filterable="true"
          :solded="true"
          auction-status="completed"
          :showAll="false"
          @vehicleChanged="
            (vehicleId, vehicle) => {
              transaction.vehicle.id = vehicleId;
              onFormChanged();
            }
          "
        />
      </base-input>
      <validation-error :errors="apiValidationErrors.vehicle" />
    </div>
    <div class="form-wrapper" v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)">
        <!-- Organization -->
        <base-input
          :label="`${$t('TRANSACTIONS.ORGANIZATION_SELLER')} (*)`"
          :placeholder="$t('TRANSACTIONS.ORGANIZATION_SELLER')"
          
        >
          <organization-selector
            :organization="transaction.seller.id"
            :filterable="true"
            :showAll="false"
            :isSeller="true"
            :seller="transaction.seller.id"
            :buyer="transaction.buyer.id"
            @organizationChanged="
              (organizationId, organization) => {
                transaction.seller.id = organizationId;
                onFormChanged();
              }
            "
            :disabled="!!transaction.id"
          />
        </base-input>
        <validation-error :errors="apiValidationErrors.seller" />
      </div>
    <div
      class="form-wrapper"
      v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)"
    >
      <!-- Organization -->
      <base-input
        :label="`${$t('TRANSACTIONS.LOCATION_SELLER')} (*)`"
        :placeholder="$t('TRANSACTIONS.LOCATION_SELLER')"
      >
        <location-selector
          :location="transaction.sellerLocation.id"
          :filterable="true"
          :showAll="false"
          :disabled="!transaction.vehicle.id"
          :organization="transaction.seller.id"
          @locationChanged="
            (locationId, location) => {
              transaction.sellerLocation.id = locationId;
              onFormChanged();
            }
          "
        />
      </base-input>
      <validation-error :errors="apiValidationErrors.sellerLocation" />
    </div>
    <div class="form-wrapper" v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)">
        <!-- Organization -->
        <base-input
          :label="`${$t('TRANSACTIONS.ORGANIZATION_BUYER')} (*)`"
          :placeholder="$t('TRANSACTIONS.ORGANIZATION_BUYER')"
          
        >
          <organization-selector
            :organization="transaction.buyer.id"
            :filterable="true"
            :showAll="false"
            :isBuyer="true"
            :seller="transaction.seller.id"
            :buyer="transaction.buyer.id"
            @organizationChanged="
              (organizationId, organization) => {
                transaction.buyer.id = organizationId;
                onFormChanged();
              }
            "
            :disabled="!!transaction.id"
          />
        </base-input>
        <validation-error :errors="apiValidationErrors.buyer" />
      </div>

    
    <div
      class="form-wrapper"
      v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)"
    >
      <!-- Organization -->
      <base-input
        :label="`${$t('TRANSACTIONS.LOCATION_BUYER')} (*)`"
        :placeholder="$t('TRANSACTIONS.LOCATION_BUYER')"
      >
        <location-selector
          :location="transaction.buyerLocation.id"
          :filterable="true"
          :showAll="false"
          :disabled="!transaction.buyer.id"
          :organization="transaction.buyer.id"
          @locationChanged="
            (locationId, location) => {
              transaction.buyerLocation.id = locationId;
              onFormChanged();
            }
          "
        />
      </base-input>
      <validation-error :errors="apiValidationErrors.buyerLocation" />
    </div>

    

    <div class="form-wrapper">
      <base-input
        :label="`${$t('TRANSACTIONS.PRICE')}`"
        :placeholder="$t('TRANSACTIONS.PRICE')"
        v-model="transaction.price"
        @change="onFormChanged()"
      />
    </div>

    <div class="submit-wrapper">
      <base-button
        type="button"
        class="btn btn-sm elite-submit"
        native-type="submit"
        :disabled="loading"
      >
        <i class="fas fa-spinner fa-spin" v-if="loading"></i>
        {{
          transaction.id
            ? $t("TRANSACTIONS.EDIT_TRANSACTION")
            : $t("TRANSACTIONS.ADD_TRANSACTION")
        }}
      </base-button>
    </div>
  </form>
</template>
<script>
import Vue from "vue";
import { cloneDeep } from "lodash";
import { Select, Option } from "element-ui";
import formMixin from "@/mixins/form-mixin";
import ValidationError from "@/components/ValidationError.vue";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import models from "@/constants/models";
import "flatpickr/dist/flatpickr.css";
import { TRANSACTIONS_STATUS } from "@/constants/transactions";
import OrganizationSelector from "@/components/OrganizationSelector.vue";
import LocationSelector from "@/components/LocationSelector.vue";
import VehicleSelector from "@/components/VehicleSelector.vue";

export default {
  layout: "DashboardLayout",

  components: {
    BaseInput,
    ValidationError,
    OrganizationSelector,
    LocationSelector,
    VehicleSelector,
    [Select.name]: Select,
    [Option.name]: Option,
  },

  mixins: [formMixin],

  props: ["transactionData", "formErrors", "loading", "vehicleId", "buyerId"],

  data() {
    let transactionData = cloneDeep(this.transactionData);
    transactionData = this.$fillUserOrganizationData(transactionData);
    return {
      transaction: transactionData,
      vehicle: {},
      TRANSACTIONS_STATUS,
      permissions: [],
      permissionsModel: {},
      models: models,
    };
  },

  created() {
    if(this.vehicleId){
      this.transaction.vehicle.id = this.vehicleId;
      this.getVehicle();
    }
    if(this.buyerId){
      this.getBuyerLocation();
    }
  },

  mounted() {},

  methods: {
    async handleSubmit() {
      let transactionData = cloneDeep(this.transaction);
      transactionData = this.$fillUserOrganizationData(transactionData);
      this.$emit("transactionSubmitted", transactionData);
    },

    async getVehicle() {
      try {
        await this.$store.dispatch("vehicles/get", this.transaction.vehicle.id);
        this.vehicle = this.$store.getters["vehicles/vehicle"];
        this.transaction.seller.id = this.vehicle.organization.id;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },
    async getBuyerLocation(){
      let params = {
        sort: "name",
        filter: {
          ...(this.buyerId ? { organization: this.buyerId } : {}),
        },
        page: {
          number: 1,
          size: 10,
        },
      };
      await this.$store.dispatch("locations/list", params);
      let locations = this.$store.getters["locations/list"];
      this.transaction.buyer.id = this.buyerId;
      this.transaction.buyerLocation.id = location[0]?.id;
    },

    onFormChanged() {
      this.getVehicle();
      this.$emit("formChanged");
    },
  },

  watch: {
    formErrors(errors) {
      if (errors) {
        this.setApiValidation(errors);
      }
    },
    transactionData(transactionData) {
      if (transactionData) {
        this.transaction = {
          ...this.transaction,
          ...cloneDeep(transactionData),
        };
      }
    },
  },
};
</script>
